import './App.css';
import React, { useEffect, useState } from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import ConsumerHomePage from './pages/consumer/consumerHomePage';
import ItineraryPage from './pages/consumer/itineraryPage';
import MyTrips from './pages/consumer/myTrips';
import HomePage from './pages/consumer/homePage';
import Authentication from './components/authentication';
import PrivateRoute from './components/privateRoute';
import Logout from './components/logout';
import ShareItineraryPage from './pages/consumer/shareItinerary';

function consumerPage(){
  return(
    <>
      <Route path='/home' element={<ConsumerHomePage/>}/>
      <Route path='/itinerary' element={<ItineraryPage/>}/>
      <Route path='/myTrips' element={<MyTrips/>}/>
      <Route path='/' element={<HomePage/>}/>
      <Route path='/login' element={<Authentication/>}/>
    </>
  )
}

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token'));

  const handleLoginSuccess = () => {
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    setIsLoggedIn(false);
  };


  return (
    <Router>
      <Routes>
        <Route 
          path="/" 
          element={<HomePage/>} 
        />
        <Route 
          path="/login" 
          element={<Authentication/>} 
        />
        <Route 
          path="/itinerary" 
          element={<ItineraryPage/>} 
        />
        <Route 
          path="/myTrips" 
          element={<PrivateRoute><MyTrips/></PrivateRoute>} 
        />
        <Route 
          path="/home" 
          element={<PrivateRoute><ConsumerHomePage/></PrivateRoute>} 
        />
        <Route 
          path="/logout" 
          element={<Logout/>} 
        />
        <Route 
          path="/sharedItinerary" 
          element={<PrivateRoute><ShareItineraryPage/></PrivateRoute>} 
        />
      </Routes>
    </Router>
  );
}

export default App;
