import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import SidePanel from '../../components/sidePanel';
import ChatPanel from '../../components/chatPanel';
import ExtraInfoPanel from '../../components/extraInfoPanel';
import '../../css/sharedPage.css';
import Itinerary from '../../components/itinerary';
import { RiRobot3Fill } from "react-icons/ri";
import axios from 'axios';

const ShareItineraryPage = () => {

    const location = useLocation();
    const serverDirection = process.env.REACT_APP_SERVER_DIRECTION;
    const websiteDirection = process.env.REACT_APP_WEBSITE_DIRECTION;
    const navigate = useNavigate();
    // Extract the tripId query parameter
    const searchParams = new URLSearchParams(location.search);
    const shareID = searchParams.get('shareID');

    const [masterJson, setMasterJson] = useState({
        "cities": [
            {
                "name": "",
                "duration": "",
            }
        ],
        "itinerary": {
        }
    });
    const [itineraryHistory, setItineraryHistory] = useState([])
    const [chatHistory, setChatHistory] = useState([]);

    useEffect(() => {
      if(shareID){
        const fetchTrip = async () => {
            const token = localStorage.getItem('token'); // Retrieve the token from localStorage
            try {
                const response = await axios.post(`${serverDirection}/get-my-itinerary`, {
                    tripId: shareID // Pass tripId in the request body
                }, {
                    headers: {
                        'Authorization': `Bearer ${token}` // Include the token in the Authorization header
                    }
                });
                let newMasterJson = response.data.trip[0].itineraryHistory[response.data.trip[0].itineraryHistory.length - 1];
                console.log(newMasterJson)
                newMasterJson.tripID = response.data.trip[0].tripID;
                setMasterJson(newMasterJson);
            } catch (error) {
                console.error('Error fetching trips:', error);
            }
        }
        fetchTrip();
      }
    }, [shareID]);

    const [extraPanelVisible, setExtraPanelVisible] = useState(false);
    const [infoType, setInfoType] = useState('');
    const [selectedActivity, setSelectedActivity] = useState(null);

    function handleSave(masterJson) {
        const token = localStorage.getItem('token'); // Retrieve the token from localStorage
        const userId = localStorage.getItem('userId'); // Assuming userId is stored in localStorage

        axios.post(`${serverDirection}/copy-shared-itinerary`, {
            masterJson: masterJson,
            userId: userId // Include userId in the request body
        }, {
            headers: {
                'Authorization': `Bearer ${token}` // Include the token in the Authorization header
            }
        })
        .then(response => {
            const tripId = response.data.tripID; // Assuming the response contains tripID
            navigate(`/itinerary?tripId=${tripId}`); // Redirect to the itinerary page with tripId
        })
        .catch(error => {
            console.error('Error saving itinerary:', error);
        });
    }

    function setParameterVisible(extraPanelVisible){
        setExtraPanelVisible(extraPanelVisible);
    }

    function handleActivitySelect(activity) {
        if (activity.tour) {
            setSelectedActivity(activity);
            setInfoType('activity');
            setExtraPanelVisible(true);
        }
    }
    
    const isItineraryEmpty = !masterJson.itinerary || Object.keys(masterJson.itinerary).length === 0;

    return (
        <div className='page-container'>
            <SidePanel/>
            <div className='page-body-container'>
                {!isItineraryEmpty? (
                    <div className='page-body page-body-full'>
                        <div className="mild-notification">
                            <p>Para alterar este itinerário, copie-o para sua conta.</p>
                            <button onClick={()=>{handleSave(masterJson)}} className="save-button">Copiar Itinerário</button>
                        </div>
                        <Itinerary openPanel={handleActivitySelect} masterJson={masterJson} setMasterJson={setMasterJson} shareItinerary={true}/>
                    </div>
                ) : (
                    <div className='page-body'>
                        <h1 className='waiting-page-h1'>Sua próxima viagem está a um clique de distância!</h1>
                        <div className='waiting-image'><img src="https://img.freepik.com/free-vector/passport-with-flight-tickets-white-background_1308-90930.jpg?t=st=1724936200~exp=1724939800~hmac=0ed4f8ea4bf0a52b01dd29417006c0412986acdfcbd49918f7d0c410f1713f7a&w=740" /></div>
                        <p className='waiting-page-paragraph'>Planeje agora e descubra novas aventuras esperando por você.</p>
                    </div>
                )}
                {extraPanelVisible ? <ExtraInfoPanel setParameter={setParameterVisible} containerInfo={selectedActivity} infoType={infoType} /> : ''}
            </div>
        </div>
    );
};

export default ShareItineraryPage;