import React, { useState, useEffect } from 'react';
import { FaArrowAltCircleRight, FaArrowAltCircleLeft } from "react-icons/fa";
import { LuCircleDashed } from 'react-icons/lu';

const HotelOptions = ({ setExtraPanelVisible, masterJson, setMasterJson }) => {
    const [selectedCity, setSelectedCity] = useState('Cidade');
    const [slideIndex, setSlideIndex] = useState(0);
    const hotelsData = masterJson;

    // Set the default city to the first city in hotelsData
    useEffect(() => {
        const cityKeys = Object.keys(hotelsData);
        if (cityKeys.length > 0 && selectedCity === 'Cidade') {
            setSelectedCity(cityKeys[0]);
        }
    }, [hotelsData, selectedCity]);

    const hotels = hotelsData[selectedCity] || [];
    const isDataLoaded = selectedCity !== 'Cidade' && hotels.length > 0;

    const plusSlides = (n) => {
        setSlideIndex((prevIndex) => (prevIndex + n + hotels.length) % hotels.length);
    };

    const currentSlide = (index) => {
        setSlideIndex(index);
    };

    // Function to handle hotel selection within a city
    const handleHotelSelection = (city, hotelId) => {
        // Update the selected state within the masterJson without resetting selectedCity or slideIndex
        setMasterJson((prev) => {
            const updatedHotelsData = {
                ...prev.hotelsData,
                [city]: prev.hotelsData[city].map((hotel) => ({
                    ...hotel,
                    selected: hotel.id === hotelId, // Select the clicked hotel, deselect others
                })),
            };
            return { ...prev, hotelsData: updatedHotelsData };
        });
        setExtraPanelVisible(true); // Open the extra panel without affecting city selection
    };

    return (
        <div className="options-container">
            <div className="container-header">
                <h2>Opções de Hotel</h2>
                <div className="destination-selector">
                    <select
                        value={selectedCity}
                        onChange={(e) => setSelectedCity(e.target.value)}
                        disabled={!isDataLoaded} // Disable the dropdown until data is loaded
                    >
                        {!isDataLoaded ? (
                            <option value="Cidade">Cidade</option>
                        ) : (
                            Object.keys(hotelsData).map((city) => (
                                <option key={city} value={city}>
                                    {city.charAt(0).toUpperCase() + city.slice(1).replace(/-/g, ' ')}
                                </option>
                            ))
                        )}
                    </select>
                </div>
            </div>

            {hotels.length === 0 ? (
                <LuCircleDashed className="loading-spinner options-container-spinner" />
            ) : (
                <div className="hotel-slider-container">
                    <div className="hotel-cards-container">
                        <a className="prev" onClick={() => plusSlides(-1)}>
                            <FaArrowAltCircleLeft />
                        </a>
                        <div className="hotel-slides">
                            <div
                                className={
                                    hotels[slideIndex].selected
                                        ? "hotel-card active card-option-selected"
                                        : "hotel-card active"
                                }
                                onClick={() => handleHotelSelection(selectedCity, hotels[slideIndex].id)}
                            >
                                <img
                                    src={hotels[slideIndex].image}
                                    alt={hotels[slideIndex].alt}
                                    className="hotel-image"
                                />
                                <div className="hotel-info">
                                    <h3>
                                        {hotels[slideIndex].name.length > 30
                                            ? hotels[slideIndex].name.slice(0, 30) + "..."
                                            : hotels[slideIndex].name}
                                    </h3>
                                    <p>
                                        {hotels[slideIndex].address.length > 40
                                            ? hotels[slideIndex].address.slice(0, 40) + "..."
                                            : hotels[slideIndex].address}
                                    </p>
                                    <div className="hotel-amenities">
                                        {hotels[slideIndex].amenities.map((amenity, index) => (
                                            <span className="amenity" key={index}>
                                                <i className={amenity.icon}></i> {amenity.name}
                                            </span>
                                        ))}
                                    </div>
                                </div>
                                <div className="hotel-price">
                                    <div className="price-details price-details-hotels">
                                        <div className="price">{hotels[slideIndex].price}</div>
                                        <div className="class">{hotels[slideIndex].details}</div>
                                    </div>
                                    <div className="rating">
                                        <span>{hotels[slideIndex].rating.score}</span>
                                        <span className="stars">{hotels[slideIndex].rating.stars}</span>
                                    </div>
                                    <button className="reserve-button">Reservar</button>
                                </div>
                            </div>
                        </div>
                        <a className="next" onClick={() => plusSlides(1)}>
                            <FaArrowAltCircleRight />
                        </a>
                    </div>
                    <div className="slider-nav">
                        {hotels.map((_, index) => (
                            <span
                                key={index}
                                className={`dot ${index === slideIndex ? 'active' : ''}`}
                                onClick={() => currentSlide(index)}
                            ></span>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default HotelOptions;
