import React, { useEffect, useState } from 'react';
import SidePanel from '../../components/sidePanel';
import ChatPanel from '../../components/chatPanel';
import DateSelector from '../../components/dateSelector';
import BudgetElement from '../../components/budgetElement';
import { FaArrowAltCircleRight, FaArrowAltCircleLeft} from "react-icons/fa";
import Itinerary from '../../components/itinerary';
import ExtraInfoPanel from '../../components/extraInfoPanel';
import { RiRobot3Fill } from "react-icons/ri";
import HotelOptions from '../../components/hotelsInfo';
import FlightOptions from '../../components/flightOptions';
import axios from 'axios';

import '../../css/tripPlannerContainer.css'

const hotelsData = {
    "rio-de-janeiro": [
        {
            "id": 1,
            "image": "hotel.jpg",
            "alt": "Radisson Hotel Barra Rio de Janeiro",
            "name": "Radisson Hotel Barra Rio de Janeiro",
            "address": "Rua Principal s/n, Jericoacoara, CEP 62598-000, Brazil",
            "amenities": [
                { "icon": "fas fa-wifi", "name": "Wifi" },
                { "icon": "fas fa-swimming-pool", "name": "Piscina" },
                { "icon": "fas fa-coffee", "name": "Café da Manhã Incluso" },
                { "icon": "fas fa-snowflake", "name": "AC Disponível" }
            ],
            "price": "R$ 4,500.00",
            "details": "3 noites, 2 adultos",
            "rating": {
                "score": 4.5,
                "stars": "★★★★★"
            }
        },
        {
            "id": 2,
            "image": "hotel.jpg",
            "alt": "Hotel Example Two",
            "name": "Hotel Example Two",
            "address": "Another Address, Rio de Janeiro, CEP 12345-000, Brazil",
            "amenities": [
                { "icon": "fas fa-wifi", "name": "Wifi" },
                { "icon": "fas fa-swimming-pool", "name": "Piscina" },
                { "icon": "fas fa-coffee", "name": "Café da Manhã Incluso" },
                { "icon": "fas fa-snowflake", "name": "AC Disponível" }
            ],
            "price": "R$ 3,500.00",
            "details": "2 noites, 1 adulto",
            "rating": {
                "score": 4.2,
                "stars": "★★★★☆"
            }
        }
    ],
    "sao-paulo": [
        {
            "id": 3,
            "image": "hotel.jpg",
            "alt": "Hotel Example Three",
            "name": "Hotel Example Three",
            "address": "Main Street, Sao Paulo, CEP 12345-678, Brazil",
            "amenities": [
                { "icon": "fas fa-wifi", "name": "Wifi" },
                { "icon": "fas fa-swimming-pool", "name": "Piscina" },
                { "icon": "fas fa-coffee", "name": "Café da Manhã Incluso" },
                { "icon": "fas fa-snowflake", "name": "AC Disponível" }
            ],
            "price": "R$ 5,000.00",
            "details": "3 noites, 2 adultos",
            "rating": {
                "score": 4.8,
                "stars": "★★★★★"
            }
        },
        {
            "id": 4,
            "image": "hotel.jpg",
            "alt": "Hotel Example Four",
            "name": "Hotel Example Four",
            "address": "Another Address, Sao Paulo, CEP 98765-432, Brazil",
            "amenities": [
                { "icon": "fas fa-wifi", "name": "Wifi" },
                { "icon": "fas fa-swimming-pool", "name": "Piscina" },
                { "icon": "fas fa-coffee", "name": "Café da Manhã Incluso" },
                { "icon": "fas fa-snowflake", "name": "AC Disponível" }
            ],
            "price": "R$ 4,200.00",
            "details": "2 noites, 1 adulto",
            "rating": {
                "score": 4.3,
                "stars": "★★★★☆"
            }
        }
    ],
    "fortaleza": [
        {
            "id": 5,
            "image": "hotel.jpg",
            "alt": "Hotel Example Five",
            "name": "Hotel Example Five",
            "address": "Beach Road, Fortaleza, CEP 55555-000, Brazil",
            "amenities": [
                { "icon": "fas fa-wifi", "name": "Wifi" },
                { "icon": "fas fa-swimming-pool", "name": "Piscina" },
                { "icon": "fas fa-coffee", "name": "Café da Manhã Incluso" },
                { "icon": "fas fa-snowflake", "name": "AC Disponível" }
            ],
            "price": "R$ 4,800.00",
            "details": "3 noites, 2 adultos",
            "rating": {
                "score": 4.7,
                "stars": "★★★★★"
            }
        },
        {
            "id": 6,
            "image": "hotel.jpg",
            "alt": "Hotel Example Six",
            "name": "Hotel Example Six",
            "address": "Another Address, Fortaleza, CEP 11111-111, Brazil",
            "amenities": [
                { "icon": "fas fa-wifi", "name": "Wifi" },
                { "icon": "fas fa-swimming-pool", "name": "Piscina" },
                { "icon": "fas fa-coffee", "name": "Café da Manhã Incluso" },
                { "icon": "fas fa-snowflake", "name": "AC Disponível" }
            ],
            "price": "R$ 3,900.00",
            "details": "2 noites, 1 adulto",
            "rating": {
                "score": 4.4,
                "stars": "★★★★☆"
            }
        }
    ]
};

const flightData = {
    "routes": [
        {
            "from": "Sao Paulo",
            "to": "Rio de Janeiro",
            "flights": [
                {
                    "id": 1,
                    "airlineLogo": "latam.png",
                    "alt": "LATAM Airlines",
                    "price": "4,500.00",
                    "class": "Classe Executiva",
                    "date": "05 de Agosto, Sábado",
                    "duration": "1h 30m",
                    "connection": ""
                },
                {
                    "id": 2,
                    "airlineLogo": "latam.png",
                    "alt": "LATAM Airlines",
                    "price": "3,000.00",
                    "class": "Classe Econômica",
                    "date": "05 de Agosto, Sábado",
                    "duration": "1h 35m",
                    "connection": ""
                },
                {
                    "id": 3,
                    "airlineLogo": "azul.png",
                    "alt": "Azul Airlines",
                    "price": "R$ 3,200.00",
                    "class": "Classe Econômica",
                    "date": "05 de Agosto, Sábado",
                    "duration": "1h 40m",
                    "connection": ""
                }
            ]
        },
        {
            "from": "Rio de Janeiro",
            "to": "Fortaleza",
            "flights": [
                {
                    "id": 4,
                    "airlineLogo": "latam.png",
                    "alt": "LATAM Airlines",
                    "price": "R$ 4,800.00",
                    "class": "Classe Executiva",
                    "date": "10 de Agosto, Quinta-feira",
                    "duration": "3h 20m",
                    "connection": "+1 conexão"
                },
                {
                    "id": 5,
                    "airlineLogo": "latam.png",
                    "alt": "LATAM Airlines",
                    "price": "R$ 3,500.00",
                    "class": "Classe Econômica",
                    "date": "10 de Agosto, Quinta-feira",
                    "duration": "3h 25m",
                    "connection": ""
                },
                {
                    "id": 6,
                    "airlineLogo": "azul.png",
                    "alt": "Azul Airlines",
                    "price": "R$ 3,700.00",
                    "class": "Classe Econômica",
                    "date": "10 de Agosto, Quinta-feira",
                    "duration": "3h 30m",
                    "connection": ""
                }
            ]
        }
    ]
};

const itineraryData = {
    "cities": [
        {
            "name": "Rio de Janeiro",
            "dates": [
                {
                    "date": "05 de Agosto",
                    "activities": [
                        {
                            "time": "09:00 - 11:00",
                            "description": "Visitar praia de Copacabana"
                        },
                        {
                            "time": "11:00 - 14:00",
                            "description": "Almoço na Cabana do Jurandi"
                        },
                        {
                            "time": "18:00 - 20:00",
                            "description": "Andar de patinete elétrico na orla do Rio de Janeiro"
                        },
                        {
                            "time": "21:00 - 23:00",
                            "description": "Jantar no Leblon Palace"
                        }
                    ]
                },
                {
                    "date": "06 de Agosto",
                    "activities": [
                        {
                            "time": "09:00 - 20:00",
                            "description": "Dia turístico visitando Arraial do Cabo"
                        }
                    ]
                },
                {
                    "date": "07 de Agosto",
                    "activities": [
                        {
                            "time": "09:00 - 11:00",
                            "description": "Visita ao Museu do Amanhã"
                        },
                        {
                            "time": "11:30 - 13:30",
                            "description": "Almoço no Jubileu de Dom Pedro I"
                        },
                        {
                            "time": "14:30 - 16:30",
                            "description": "Visita ao Parque Laje e Trilha"
                        }
                    ]
                },
                {
                    "date": "08 de Agosto",
                    "activities": [
                        {
                            "time": "09:00 - 11:00",
                            "description": "Visita ao estádio do Maracanã"
                        },
                        {
                            "time": "11:30 - 15:30",
                            "description": "Dia de praia e almoço no Leblon"
                        },
                        {
                            "time": "16:30 - 20:30",
                            "description": "Pôr do sol e jantar no Pão de Açúcar"
                        }
                    ]
                }
            ]
        },
        {
            "name": "São Paulo",
            "dates": [
                {
                    "date": "09 de Agosto",
                    "activities": [
                        {
                            "time": "10:00 - 12:00",
                            "description": "Visita ao Museu de Arte de São Paulo"
                        },
                        {
                            "time": "12:30 - 14:00",
                            "description": "Almoço na Avenida Paulista"
                        },
                        {
                            "time": "15:00 - 18:00",
                            "description": "Compras na Rua 25 de Março"
                        }
                    ]
                }
            ]
        }
    ]
};

const destinationInfo = {
    "destinationName": "Radisson Hotel Barra Rio de Janeiro",
    "rating": 4.5,
    "images": [
        "hotel.jpg", // Replace with actual image paths
        "https://www.melhoresdestinos.com.br/wp-content/uploads/2019/08/rio-de-janeiro-capa2019-01.jpg",
        "hotel.jpg",
        "https://www.melhoresdestinos.com.br/wp-content/uploads/2019/08/rio-de-janeiro-capa2019-01.jpg",
        "hotel.jpg"
    ],
    "reviews": [
        {
            "userName": "Michel Araújo",
            "userImage": "robot.png", // Replace with actual image path
            "date": "04/10/2024",
            "content": "Tudo excelente, desde o quarto, restaurante e atendimento. Cama e chuveiros muito bons. Café da manhã com uma enorme variedade e tudo delicioso. E o restaurante para almoço e jantar são sempre uma excelente pedida. E ótimo custo benefício.",
            "score": "★★★★★"
        },
        {
            "userName": "Maria Oliveira",
            "userImage": "robot.png",
            "date": "03/10/2024",
            "content": "Localização ótima, perto da praia. Quarto confortável e limpo.",
            "score": "★★★★☆"
        },
        {
            "userName": "João Silva",
            "userImage": "robot.png",
            "date": "02/10/2024",
            "content": "O atendimento deixou a desejar, mas a estrutura do hotel é muito boa.",
            "score": "★★★☆☆"
        },
        {
            "userName": "Ana Clara",
            "userImage": "robot.png",
            "date": "01/10/2024",
            "content": "Ótima piscina, café da manhã muito bom e variado.",
            "score": "★★★★★"
        },
        {
            "userName": "Carlos Eduardo",
            "userImage": "robot.png",
            "date": "30/09/2024",
            "content": "Cama muito confortável, mas o ar condicionado fazia barulho.",
            "score": "★★★★☆"
        }
    ],
    "location": {
        "address": "Radisson Hotel Barra Rio de Janeiro",
        "googleMapsLink": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31848.594473417448!2d-38.47447775!3d-3.7939864!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7c745eece2c2e0f%3A0x2380683623a21b9c!2sColosso%20Fortaleza!5e0!3m2!1spt-BR!2sbr!4v1724263630805!5m2!1spt-BR!2sbr" // Replace with the actual Google Maps embed link
    }
}

const ConsumerHomePage = () => {
    const [extraPanelVisible, setExtraPanelVisible] = useState(false);
    const [chatVisible, setChatVisible] = useState();
    const [masterJson, setMasterJson] = useState({
        "routes": [
            {
                "from": "",
                "to": "",
                "travelDate": "",
            }
        ],
        "hotelsData": {
            "hotelName": "",
            "hotelAddress": "",
            "checkInDate": "",
            "checkOutDate": "",
            "roomType": "",
            "pricePerNight": "",
            "totalPrice": ""
        },
        "flightsData": {
            "flightNumber": "",
            "airline": "",
            "departureTime": "",
            "arrivalTime": "",
            "departureAirport": "",
            "arrivalAirport": "",
            "price": "",
            "layovers": []
        },
        "itinerary": {
        }
    });

    const hasCompleteRoute = masterJson.routes.every(route => 
        route.from && route.to && route.travelDate
    );

    let flightsCost;
    let hotelsCost;

    useEffect(()=>{
        
    }, [masterJson])

    function setParameterVisible(extraPanelVisible){
        setExtraPanelVisible(extraPanelVisible);
    }

    return (
        <div className='page-container'>
            <SidePanel/>
            <div className='page-body-container'>
                {hasCompleteRoute ? 
                <div className='page-body'>
                    <div className='banner-container'>
                        <p>SUA VIAGEM COMEÇA AGORA!</p>
                    </div>
                    <DateSelector routes={masterJson.routes} />
                    <BudgetElement masterJson={masterJson}/>
                    <hr className='container-divider'></hr>
                    <FlightOptions masterJson={masterJson} setMasterJson={setMasterJson}/>
                    <hr className='container-divider'></hr>
                    <HotelOptions masterJson={masterJson} setMasterJson={setMasterJson} setExtraPanelVisible={setExtraPanelVisible}/>
                    <hr className='container-divider'></hr>
                    <Itinerary openPanel={()=>{setExtraPanelVisible(true)}} itineraryData={masterJson.itinerary}/>
                    <div className="warning-container">
                        <div className="warning-header">AVISOS</div>
                        <div className="warning-content">
                            <p>Cuidado com furtos em locais públicos no Rio de Janeiro</p>
                            <p>Fortes chuvas entre Julho e Novembro no Rio de Janeiro</p>
                        </div>
                    </div>
                </div>
                : 
                <div className='page-body'>
                    <h1 className='waiting-page-h1'>Sua próxima viagem está a um clique de distância!</h1>
                    <div className='waiting-image'><img src="https://img.freepik.com/free-vector/passport-with-flight-tickets-white-background_1308-90930.jpg?t=st=1724936200~exp=1724939800~hmac=0ed4f8ea4bf0a52b01dd29417006c0412986acdfcbd49918f7d0c410f1713f7a&w=740" /></div>
                    <p className='waiting-page-paragraph'>Planeje agora e descubra novas aventuras esperando por você.</p>
                </div>
                }
                <ChatPanel page={"trip"} chatVisibility = {chatVisible} setChatVisibility={setChatVisible} masterJson = {masterJson} setMasterJson={setMasterJson}/>
                {extraPanelVisible ? <ExtraInfoPanel setParameter={setParameterVisible} destinationInfo={destinationInfo}/> : ''}
                {chatVisible == "flex" ? '' : <div className="chat-icon-container" onClick={()=>{setChatVisible("flex")}}><RiRobot3Fill /><a>Planner</a></div>}
            </div>
        </div>
    );
};

export default ConsumerHomePage;