import React, { useState } from 'react';
import '../css/extraInfoPanel.css';
import { IoMdCloseCircle } from "react-icons/io";
import { FaArrowAltCircleRight, FaArrowAltCircleLeft } from "react-icons/fa";

const ImageGallery = ({ containerInfo }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const currentImage = containerInfo.images[currentIndex];

    return (
        <div className="gallery-container">
            <h2>{containerInfo.destinationName}</h2>
            <div className="main-image">
                <img src={currentImage} alt="Main display" />
            </div>
            <div className="thumbnail-container">
                {containerInfo.images.map((image, index) => (
                    <img
                        key={index}
                        src={image}
                        alt={`Thumbnail ${index + 1}`}
                        className={index === currentIndex ? 'active' : ''}
                        onClick={() => setCurrentIndex(index)}
                    />
                ))}
            </div>
        </div>
    );
};

const Reviews = ({ containerInfo }) => {
    const [currentReviewIndex, setCurrentReviewIndex] = useState(0);
    const currentReview = containerInfo.reviews[currentReviewIndex];

    return (
        <div className="reviews-container">
            <h3>Avaliações</h3>
            <hr className='sub-divider'></hr>
            <div className="review">
                <div className="review-header">
                    <img src={currentReview.userImage} alt={currentReview.userName} />
                    <div>
                        <h4>{currentReview.userName}</h4>
                        <p>{currentReview.date}</p>
                    </div>
                    <div className="rating">{currentReview.score}</div>
                </div>
                <p>{currentReview.content}</p>
            </div>
            <div className="reviews-nav">
                {containerInfo.reviews.map((_, index) => (
                    <span
                        key={index}
                        className={`dot ${index === currentReviewIndex ? 'active' : ''}`}
                        onClick={() => setCurrentReviewIndex(index)}
                    ></span>
                ))}
            </div>
        </div>
    );
};

const MapEmbed = ({ containerInfo }) => {
    return (
        <div className="reviews-container">
            <h3>Localização</h3>
            <hr className='sub-divider'></hr>
            <iframe
                src={containerInfo.location.googleMapsLink}
                width="100%"
                height="300"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title={containerInfo.location.address}
            ></iframe>
        </div>
    );
};

const TourList = ({ tours, expandTour }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => 
            prevIndex === tours.length - 1 ? 0 : prevIndex + 1
        );
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => 
            prevIndex === 0 ? tours.length - 1 : prevIndex - 1
        );
    };

    const TourCard = ({ image, title, description, duration, rating, price, link, reviewCount }) => (
        <div className="tour-card" onClick={()=>{window.open(link)}}>
            <div className='image-container'>
                <img src={image} alt={title} className="tour-image" />
            </div> 
          <div className="tour-info">
            <h3>{title}</h3>
            <p>{description}...</p>
            <b><p>Duração: {duration === 0 ? 'Duração não especificada' : 
 duration < 60 ? 
   `${duration} Minuto${duration !== 1 ? 's' : ''}` :
   `${Math.floor(duration/60)} Hora${Math.floor(duration/60) !== 1 ? 's' : ''}${duration % 60 > 0 ? ` e ${duration % 60} Minuto${duration % 60 !== 1 ? 's' : ''}` : ''}`
}</p></b>
            <div className="tour-footer">
              <span className="tour-rating">★ {rating} ({reviewCount})</span>
              <span className="tour-price">A partir de R${price}</span>
            </div>
          </div>
        </div>
    );
    return (
        <div className="tours-carousel">
            <h3>Tours Disponíveis</h3>
            <div className='carousel-container'>
                <div className="carousel-container">
                    {tours.map((tour, index) => (
                        <div
                            key={tour.id}
                            className={`carousel-item ${index === currentIndex ? 'active' : ''}`}
                        >
                            <TourCard
                                image={tour.image.url}
                                title={tour.title}
                                description={tour.description.slice(0,75)}
                                duration={tour.duration}
                                rating={Math.round(tour.reviews.averageRating * 100)/100}
                                reviewCount = {tour.reviews.totalReviews}	
                                price={tour.pricing.fromPrice}
                                link={tour.productURL}
                            />
                    </div>
                ))}
                </div>     
            </div>          
        </div>
      );
};

const ExtraInfoPanel = ({ setParameter, containerInfo, infoType, expandTour }) => {
    const [selectedTour, setSelectedTour] = useState(null);

    const handleSelectTour = (tour) => {
        setSelectedTour(tour);
    };

    return (
        <div className='extra-info-container'>
            <IoMdCloseCircle className='exit-button' onClick={() => { setParameter(false) }} />
            {infoType === 'destination' && (
                <>
                    <ImageGallery containerInfo={containerInfo} />
                    <Reviews containerInfo={containerInfo} />
                    <MapEmbed containerInfo={containerInfo} />
                </>
            )}
            {infoType === 'activity' && !selectedTour && (
                <>  
                    <TourList tours={containerInfo.tours} expandTour={expandTour}/>
                </>

            )}
            {infoType === 'activity' && selectedTour && (
                <>
                    <ImageGallery containerInfo={selectedTour} />
                    <Reviews containerInfo={selectedTour} />
                    <MapEmbed containerInfo={selectedTour} />
                </>
            )}
        </div>
    );
};

export default ExtraInfoPanel;