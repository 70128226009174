import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../css/authenticationPage.css';

const Authentication = ({ redirectPath = '/itinerary', onClose, onAuthSuccess}) => {
    const navigate = useNavigate();
    const [isLogin, setIsLogin] = useState(true);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [confirmEmail, setConfirmEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const serverDirection = process.env.REACT_APP_SERVER_DIRECTION;
    const websiteDirection = process.env.REACT_APP_WEBSITE_DIRECTION;

    
    const isModal = !!onClose;

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        if (!isLogin) {
          // Additional validation for sign-up
            if (email !== confirmEmail) {
                setError('Emails não coincidem');
                return;
            }
            if (password !== confirmPassword) {
                setError('Passwords não coincidem');
            return;
        }
      }
    try {
        let response;
        if (isLogin) {
            // Login request
            response = await axios.post(`${serverDirection}/auth/login`, { email, password });
        } else {
            // Signup request
            response = await axios.post(`${serverDirection}/auth/signup`, { name, email, password });
        }
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('user', JSON.stringify(response.data.user));
        if (isModal) {
            onAuthSuccess && onAuthSuccess();
            onClose();
        } else {
            navigate(redirectPath);
        }
    } catch (error) {
      setError('Credenciais inválidas');
    }
  };

  return (
    <div className="auth-container">
      <div className="auth-form">
         {isModal && (
            <button className="close-button" onClick={onClose}>
                &times;
            </button>
        )}
        <div className="auth-logo">
          <img src={`${websiteDirection}/lilo2.png`} alt="Lilo Logo" />
        </div>
        {error && <p className="error">{error}</p>}
        <form onSubmit={handleSubmit}>
          {!isLogin && (
            <input
              type="text"
              placeholder="Nome e Sobrenome"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          )}
          <input
            type="email"
            placeholder="E-mail"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          {!isLogin && (
            <input
              type="email"
              placeholder="Confirme seu e-mail"
              value={confirmEmail}
              onChange={(e) => setConfirmEmail(e.target.value)}
              required
            />
          )}
          <input
            type="password"
            placeholder="Senha"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          {!isLogin && (
            <input
              type="password"
              placeholder="Confirme sua senha"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          )}
          <button type="submit">{isLogin ? 'Login' : 'Registrar'}</button>
        </form>
        <div className="switch-auth">
            <button onClick={() => {
                setIsLogin(!isLogin);
                setError('');
            }}>
                {isLogin ? "Não possui uma conta?" : "Já possui uma conta?"}
            </button>
        </div>
      </div>
    </div>
  );
};

export default Authentication;