import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/logout.css';

const Logout = () => {
    const navigate = useNavigate();
    const serverDirection = process.env.REACT_APP_SERVER_DIRECTION;
    const websiteDirection = process.env.REACT_APP_WEBSITE_DIRECTION;

    useEffect(() => {
        // Clear the authentication token and user data from localStorage
        localStorage.removeItem('token');
        localStorage.removeItem('user');

        // Redirect to home page after 2 seconds
        const timer = setTimeout(() => {
            navigate('/');
        }, 2000);

        // Clean up the timer
        return () => clearTimeout(timer);
    }, [navigate]);

    return (
        <div className="logout-container">
            <img src={`${websiteDirection}/lilo2.png`} alt="Lilo Logo" className="logout-logo" />
            <h2>Até logo!</h2>
        </div>
    );
};

export default Logout;