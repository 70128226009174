import React, { useState, useEffect } from 'react';
import SidePanel from '../../components/sidePanel';
import { useNavigate } from 'react-router-dom';
import '../../css/plannedTrips.css';
import { FaRegCalendarAlt } from "react-icons/fa";
import { MdOutlineLocalActivity } from "react-icons/md";
import { LuCircleDashed } from 'react-icons/lu';
import axios from 'axios';

const getUserId = () => {
    const userString = localStorage.getItem('user');

    if (userString) {
        try {
            const user = JSON.parse(userString);
            return user.id;
        } catch (error) {
            console.error('Error parsing user data:', error);
        }
    }
    return null;
};
  
const PlannedTrips = ({ trips, loadingTrips }) => {
  const navigate = useNavigate();

  const handleAddTrip = () => {
      navigate('/itinerary');
  };

  return (
      <div className="planned-trips-container">
          <div className="header">
              <h2>Viagens Planejadas</h2>
              <button className="add-trip-button" onClick={()=>{navigate('/itinerary')}}>
                  Adicionar Viagem
              </button>
          </div>
          {loadingTrips ? (
              <LuCircleDashed className='loading-spinner my-trips-spinner'/>
          ) : trips && trips.length > 0 ? (
              <div className="trips-grid">
                  {trips.map((trip, index) => (
                      <div key={index} className="trip-card" onClick={()=>{navigate(`/itinerary?tripId=${trip.tripId}`)}}>
                          {trip.imageUrl ? <img src={trip.imageUrl} alt={trip.cities} className="trip-image" /> : ""}
                          <div className="trip-info">
                              <h3>{trip.cities}</h3>
                              <p><FaRegCalendarAlt className='trip-icon' />{trip.duration} days</p>
                              <p className='trip-activity'><MdOutlineLocalActivity className='trip-icon' />Atividades Disponíveis: {trip.activitiesCount}</p>
                          </div>
                      </div>
                  ))}
              </div>
          ) : (
            <>
              <p>Nenhuma viagem planejada</p>
              <button className="add-trip-button" onClick={()=>{navigate('/itinerary')}}>
                  Adicionar Viagem
              </button>
            </>
          )}
      </div>
  );
};

const MyTrips = () => {

    const [trips, setTrips] = useState([]);
    const userId = getUserId(); // Replace with your method of getting the user ID
    const [loadingTrips, setLoadingTrips] = useState(true);
    const serverDirection = process.env.REACT_APP_SERVER_DIRECTION;
    const websiteDirection = process.env.REACT_APP_WEBSITE_DIRECTION;

    useEffect(() => {
      const fetchTrips = async () => {
        setLoadingTrips(true);
        const token = localStorage.getItem('token'); // Retrieve the token from localStorage
        try {
            const response = await axios.post(`${serverDirection}/get-my-trips`, {
                userId: userId // Pass userId in the request body
            }, {
                headers: {
                    'Authorization': `Bearer ${token}` // Include the token in the Authorization header
                }
            });
            console.log('Fetched trips:', response.data);
            setLoadingTrips(false);
            setTrips(response.data);
        } catch (error) {
            console.error('Error fetching trips:', error);
            setLoadingTrips(false);
        }
      };

        fetchTrips();
    }, [userId]);

    return (
        <div className='page-container'>
            <SidePanel/>
            <div className='page-body-container'>
                <PlannedTrips trips={trips} loadingTrips={loadingTrips}/>
            </div>
        </div>
    );
}

export default MyTrips;