import React, { useState, useEffect } from 'react';
import { LuCircleDashed } from 'react-icons/lu';
import { BsStars } from "react-icons/bs";
import { FaLink, FaFileDownload  } from 'react-icons/fa';
import '../css/itinerary.css';
import { isUserLoggedIn } from '../utils/auth';
import Authentication from './authentication'; // Import your existing Authentication component
import html2pdf from 'html2pdf.js';
import '../css/pdfStyles.css'; // Adjust the path as necessary

const Itinerary = ({ openPanel, masterJson, itineraryHistory, setMasterJson, shareItinerary}) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [showAuthModal, setShowAuthModal] = useState(false);
    const [selectedCity, setSelectedCity] = useState("City");
    const serverDirection = process.env.REACT_APP_SERVER_DIRECTION;
    const websiteDirection = process.env.REACT_APP_WEBSITE_DIRECTION;
    const [messageVisible, setMessageVisible] = useState(false);

    let itineraryData = masterJson.itinerary;

    useEffect(() => {
        setIsLoggedIn(isUserLoggedIn());
    }, []);

    const handleShare = () => {
        const link = websiteDirection + '/sharedItinerary?shareID=' + masterJson.tripID // Get the current URL
        navigator.clipboard.writeText(link) // Copy the link to the clipboard
            .then(() => {
                setMessageVisible(true); // Show the message
                setTimeout(() => {
                    setMessageVisible(false); // Hide the message after 3 seconds
                }, 3000);
            })
            .catch(err => {
                console.error('Failed to copy: ', err);
            });
    };

    const handleSave = () => {
        if (isLoggedIn) {

        } else {
            setShowAuthModal(true);
        }
    };

    const handleDownloadPDF = () => {
        // Check if itineraryData is available
        if (!itineraryData || itineraryData.length === 0) {
            console.error('Itinerary data is not available.');
            return;
        }
    
        // Create a div to hold the PDF content
        const pdfContent = document.createElement('div');
        pdfContent.className = 'pdf-itinerary'; // Use the class defined in the CSS file
    
        // Add the logo at the top left corner of the PDF
        const logoURL = 'lilo2.png'; // Replace with the actual path to your logo
        const logoHTML = `<img src="${logoURL}" alt="Lilo Logo" style="width: 60px;" />`;
        pdfContent.innerHTML += logoHTML; // Add logo HTML
    
        // Manually build the content from itineraryData
        itineraryData.forEach(city => {
            const cityHTML = `
                <div class="itinerary-date">
                <div class='date-info'>
                    <h3>${city.name}</h3>
                    ${city.dates.map(date => `
                        <div class="date" style="margin-bottom:20px">
                            <h4>${date.date}</h4>
                            ${date.activities.map(activity => `
                                <div class='activity' style='justify-content:flex-start'>
                                    <span name="time"><b>${activity.time}</b> | </span>
                                    <span name="description" style='margin-left:5px'> ${activity.description}</span>
                                </div>
                            `).join('')}
                        </div>
                    `).join('')}
                    </div>
                </div>
            `;
            pdfContent.innerHTML += cityHTML;
        });
    
        // Temporarily append the content to the DOM to generate the PDF
        document.body.appendChild(pdfContent);
        pdfContent.style.display = 'hidden'; // Keep it hidden from the actual view
    
        // Set options for PDF generation
        const options = {
            margin: [0.5, 0.5, 0.5, 0.5],
            filename: 'meu-intinerario.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
            pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
        };
    
        // Generate the PDF
        html2pdf().from(pdfContent).set(options).save().then(() => {
            // Clean up the temporary element after generating the PDF
            document.body.removeChild(pdfContent);
        }).catch(err => {
            console.error('Error generating PDF:', err);
        });
    };

    const handleAuthSuccess = async () => {
        setIsLoggedIn(true);
        setShowAuthModal(false);

        const userString = localStorage.getItem('user');
        if (userString) {
            const user = JSON.parse(userString);
            const userId = user.id;

            // Update user association for existing tripId and chatId
            await updateUserAssociation(userId, masterJson.tripID, masterJson.chatID);
        }
    };

    const updateUserAssociation = async (userId, tripId, chatId) => {
        try {
            const token = localStorage.getItem('token');
    
            if (!token) {
                throw new Error('No authentication token found');
            }
    
            const response = await fetch(`${serverDirection}/updateUserAssociation`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ userId, tripId, chatId })
            });
    
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to update user association');
            }
    
            const result = await response.json();
            console.log('User association updated:', result);
        } catch (error) {
            console.error('Error updating user association:', error);
            // Handle the error appropriately in your UI
        }
    };

    const handleCityChange = (e) => {
        setSelectedCity(e.target.value);
    };

    const handleCityButtonClick = (city) => {
        setSelectedCity(city);
    };

    // Check if itinerary data is loaded
    const isDataLoaded = itineraryData && itineraryData.length > 0;
    // Find the selected city in the itinerary data
    const city = isDataLoaded ? itineraryData.find(city => city.name === selectedCity) : null;
    useEffect(() => {
        if (isDataLoaded && (!selectedCity || !itineraryData.some(city => city.name === selectedCity))) {
            setSelectedCity(itineraryData[0].name);
        }
    }, [isDataLoaded, itineraryData, selectedCity]);

    return (
        <div className="options-container">
            {messageVisible && (
                <div className='positive-notification'>
                    Link Copiado
                </div>
            )}
            {!isLoggedIn && (
                <div className="login-banner">
                    <p>Você não está logado, seu itinerário não será salvo</p>
                    <button onClick={handleSave} className="save-button">Salvar Intinerário</button>
                </div>
            )}
            <div className='buttons-row-container'>
                {itineraryHistory && itineraryHistory.length > 1 ? (
                    <div className="destination-selector">
                        <select onChange={(e) => {
                            const selectedIndex = e.target.value; // Get the selected index
                            const selectedItinerary = itineraryHistory[selectedIndex]; // Get the corresponding itinerary
                            let newSelection = {
                                dateCreated: masterJson.dateCreated,
                                tripID: masterJson.tripID,
                                itinerary: selectedItinerary.itinerary,
                            }
                            setMasterJson(newSelection); // Update masterJson with the selected itinerary
                        }}>
                            {itineraryHistory.map((itinerary, index) => (
                                <option key={index} value={index}>
                                    {index === 0 ? "Versão Atual" : index === 1 ? "Versão Anterior" : index === 2 ? "Versão Retrasada" : ''}
                                </option>
                            ))}
                        </select>
                    </div>
                ) : ''}
                {!shareItinerary ? (
                    <div onClick={handleShare} className="share-link">
                        <FaLink /> Compartilhar Itinerário
                    </div>
                ) : ''}
                {isDataLoaded ? (
                    <div onClick={handleDownloadPDF} className="share-link">
                        <FaFileDownload /> PDF
                    </div>
                ) : ''}
            </div>
            <div id="itinerary">
                <div className="container-header">
                    <h2>Seu Itinerário</h2>
                    <div className="destination-selector">
                        <select
                            id="city-select"
                            value={selectedCity}
                            onChange={handleCityChange}
                            disabled={!isDataLoaded} // Disable dropdown until data is loaded
                        >
                            {!isDataLoaded ? (
                                <option value="City">Cidade</option>
                            ) : (
                                itineraryData.map((city, index) => (
                                    <option key={index} value={city.name}>{city.name}</option>
                                ))
                            )}
                        </select>
                    </div>
                </div>
            
                {!isDataLoaded || !city ? (
                    <LuCircleDashed className='loading-spinner options-container-spinner' />
                ) : (
                    city.dates.map((date, index) => {
                        // Find the first activity with a tour
                        const tourActivity = date.activities.find(activity => activity.tour);
                        // Get the image URL from the first tour in the activity, if it exists
                        const imageUrl = tourActivity && tourActivity.tours && tourActivity.tours[0] && tourActivity.tours[0].image
                            ? tourActivity.tours[0].image.url
                            : '';
                
                        return (
                            <div key={index} className="itinerary-date">
                                {imageUrl ?<img src={imageUrl} alt={date.date} /> : ''}
                                <div className="date-info">
                                    <h3>{date.date}</h3>
                                    <p>{city.name}</p>
                                    {date.activities.map((activity, i) => (
                                        <div key={i} className="activity" style={{cursor: activity.tour ? 'pointer' : 'default'}} onClick={() => openPanel(activity) }>
                                            <span className="time">{activity.time}</span>
                                            <span className="description">{activity.description.slice(0,100)}...</span>
                                            {activity.tour ? (<div className='tour-availability'><BsStars className='star-icon'/>Tours</div>) : ''}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )
                    })
                )}
                {!isDataLoaded || !city ? '' : (
                    <div className="city-nav-buttons">
                        {itineraryData.map((city, index) => (
                            <button
                                key={index}
                                onClick={() => handleCityButtonClick(city.name)}
                                className={city.name === selectedCity ? "active" : ""}
                                disabled={!isDataLoaded} // Disable buttons until data is loaded
                            >
                                {city.name}
                            </button>
                        ))}
                    </div>
                )}
            </div>
            {showAuthModal && (
                <Authentication
                    onClose={() => setShowAuthModal(false)}
                    onAuthSuccess={handleAuthSuccess}
                />
            )}
        </div>
    );
};

export default Itinerary;
