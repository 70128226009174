import React, { useState, useEffect } from 'react';
import { format, addDays, eachDayOfInterval, parse } from 'date-fns';
import { ptBR } from 'date-fns/locale';

// Function to generate itinerary dates and locations
const generateItineraryDates = (routes) => {
  // Check if routes are empty or undefined
  if (!routes || routes.length === 0) {
    return { itinerary: [], dateRange: 'No routes available' };
  }

  // Parse the start and end dates based on the first and last travel dates
  const startDate = parse(routes[0].travelDate, 'dd-MM-yyyy', new Date());
  const endDate = parse(routes[routes.length - 1].travelDate, 'dd-MM-yyyy', new Date());

  // Generate all dates between the start and end of the trip
  const allDates = eachDayOfInterval({ start: startDate, end: addDays(endDate, 0) });

  // Map each day to its respective city based on the routes
  let currentCity = routes[0].to; // Start in the first destination
  let cityIndex = 0;

  const itinerary = allDates.map((date) => {
    // If the current date matches the next travel date, update the city
    const nextRoute = routes[cityIndex + 1];
    if (nextRoute && format(date, 'dd-MM-yyyy') === nextRoute.travelDate) {
      currentCity = nextRoute.to;
      cityIndex++;
    }

    return {
      date: format(date, 'dd \'de\' MMMM', { locale: ptBR }),
      location: currentCity,
    };
  });

  // Overall date range for display
  const dateRange = `${format(startDate, 'dd', { locale: ptBR })} - ${format(endDate, 'dd \'de\' MMMM', { locale: ptBR })}`;

  return { itinerary, dateRange };
};

const DateSelector = ({routes}) => {
  const [tripDates, setTripDates] = useState({ itinerary: [], dateRange: '' });

  useEffect(() => {
    const dates = generateItineraryDates(routes);
    setTripDates(dates);
  }, []);

  return (
    <div className="date-selector-container">
      {tripDates.itinerary.length > 0 ? (
        <>
          <div className="date-range">
            {tripDates.dateRange}
          </div>
          <div className="date-grid">
            {tripDates.itinerary.map((item, index) => (
              <div className="date-item" key={index}>
                <div className="date">{item.date}</div>
                <div className="location">{item.location}</div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className="no-routes-message">
          Nenhuma rota disponível. Por favor, adicione rotas para visualizar o itinerário.
        </div>
      )}
    </div>
  );
};

export default DateSelector;
