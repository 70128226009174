import React, { useState, useEffect } from 'react';
import { LuCircleDashed } from 'react-icons/lu';

const FlightOptions = ({ masterJson, setMasterJson }) => {
    const [selectedRouteIndex, setSelectedRouteIndex] = useState(null);
    const flightData = masterJson.flightsData;
    // Check if flightData is loaded and valid
    const routesLoaded = flightData && flightData.routes && flightData.routes.length > 0;

    useEffect(() => {
        if (routesLoaded) {
            setSelectedRouteIndex(0); // Set to the first route if data is loaded
        }
    }, [routesLoaded]);

    const selectedRoute = routesLoaded ? flightData.routes[selectedRouteIndex] : null;

    // Function to handle flight selection within a route
    const handleFlightSelection = (routeIndex, flightId) => {
        // Update the selected state within the masterJson
        const updatedRoutes = flightData.routes.map((route, index) => {
            if (index === routeIndex) {
                // Update only the flights in the selected route
                const updatedFlights = route.flights.map((flight) => ({
                    ...flight,
                    selected: flight.id === flightId, // Select the clicked flight, deselect others
                }));
                return { ...route, flights: updatedFlights };
            }
            return route;
        });

        // Update the masterJson with the new selected state
        setMasterJson((prev) => ({
            ...prev,
            flightsData: {
                ...prev.flightsData,
                routes: updatedRoutes,
            },
        }));
    };

    return (
        <div className="options-container">
            <div className="container-header">
                <h2>Opções de Vôo</h2>
                <div className="destination-selector">
                    <select
                        value={selectedRouteIndex !== null ? selectedRouteIndex : "Sua Viagem"}
                        onChange={(e) => setSelectedRouteIndex(Number(e.target.value))}
                        disabled={!routesLoaded} // Disable dropdown until data is loaded
                    >
                        {!routesLoaded ? (
                            <option value="Sua Viagem">Sua Viagem</option>
                        ) : (
                            flightData.routes.map((route, index) => (
                                <option key={index} value={index}>
                                    {route.from} - {route.to}
                                </option>
                            ))
                        )}
                    </select>
                </div>
            </div>

            {!routesLoaded || !selectedRoute || selectedRoute.flights.length === 0 ? (
                <LuCircleDashed className="loading-spinner options-container-spinner" />
            ) : (
                <div className="flight-cards">
                    {selectedRoute.flights.map((flight) => (
                        <div
                            className={flight.selected ? "flight-card card-option-selected" : "flight-card"}
                            key={flight.id}
                            onClick={() => handleFlightSelection(selectedRouteIndex, flight.id)}
                        >
                            <div className="flight-card-header">
                                <img src={flight.airlineLogo} alt={flight.alt} className="airline-logo" />
                                <div className="price-details">
                                    <div className="price">{flight.price}</div>
                                    <div className="class">{flight.class}</div>
                                </div>
                            </div>
                            <div className="flight-card-body">
                                <div className="date">{flight.date}</div>
                                <div className="route-container">
                                    <div className="route">
                                        <span>{selectedRoute.from}</span>
                                        <hr />
                                        <span>{selectedRoute.to}</span>
                                    </div>
                                    <div className="flight-time">
                                        {flight.duration} {flight.connection && `(${flight.connection})`}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default FlightOptions;
