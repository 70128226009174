import { React, useEffect, useState } from 'react';
import {ReactTyped} from 'react-typed';
import { useNavigate, Link } from 'react-router-dom';
import { isUserLoggedIn } from '../../utils/auth';
import { IoMdSend } from "react-icons/io";
import '../../css/home.css';

const phrases = [
  'Uma viagem de 5 dias para o Rio de Janeiro, partindo dia 20 de janeiro...',
  'Planeje uma viagem romântica para Paris em julho...',
  'Quero fazer um mochilão pela Europa por 3 semanas...',
  'Preciso de um roteiro de 7 dias para Nova York com crianças...',
  'Planeje uma aventura de ecoturismo na Costa Rica para 10 dias...'
];

const HomePage = () => {
    const [inputValue, setInputValue] = useState('');
    const [errorMessage, setErrorMessage] = useState(''); // State for error message
    const navigate = useNavigate();
    const websiteDirection = process.env.REACT_APP_WEBSITE_DIRECTION;

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
        setErrorMessage(''); // Clear error message on input change
    };

    useEffect(() => {
        if (isUserLoggedIn()) {
            navigate('/myTrips');
        }
    }, [navigate]);

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            if (inputValue.trim()) {
                navigate('/itinerary', { state: { initialMessage: inputValue } });
            } else {
                setErrorMessage('Que tal descrever sua viagem antes de começarmos?'); // Set error message
            }
        }
    };

    const handleButtonClick = () => {
        if (inputValue.trim()) {
            navigate('/itinerary', { state: { initialMessage: inputValue } });
        } else {
            setErrorMessage('Que tal descrever sua viagem antes de começarmos?'); // Set error message
        }
    };

    
    return (
        <div className="home-container" style={{
            backgroundImage: `url(${websiteDirection}/background.png)`,
          }}>
            {isUserLoggedIn() ? '' : <Link to="/login" className="login-button">Login</Link>}
            <div className="content">
                <h1>SUA AVENTURA COMEÇA AGORA</h1>
                <div className="input-container">
                    <div className="input-button-wrapper">
                        <ReactTyped
                            strings={phrases}
                        typeSpeed={40}
                        backSpeed={50}
                        attr="placeholder"
                            loop
                        >
                            <input  type="text" 
                                    value={inputValue}
                                onChange={handleInputChange}
                                onKeyPress={handleKeyPress} />
                        </ReactTyped>
                    </div>
                    <button className="send-button" onClick={handleButtonClick}><IoMdSend />
                    </button>
                </div>
                {errorMessage && <div className="error-message">{errorMessage}</div>} {/* Display error message */}
                </div>
        </div>
  );
};

export default HomePage;
