import React from 'react';
import { LuCircleDashed } from 'react-icons/lu';

// Function to format numbers to BRL currency format
const formatCurrency = (value) => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
  }).format(value);
};

const parsePrice = (price) => {
    // Check if the price has a comma followed by two digits (indicating it's the decimal separator)
    if (price.includes(',') && price.split(',')[1].length === 2) {
      // Handle formats like "4.500,00"
      price = price.replace(/\./g, '').replace(',', '.');
    } else {
      // Handle formats like "4,500.00"
      price = price.replace(/,/g, '');
    }
    return parseFloat(price);
  };

const BudgetElement = ({ masterJson }) => {
  // Function to calculate total hotel costs
  const calculateHotelCosts = (hotelsData) => {
    if (!hotelsData || Object.keys(hotelsData).length === 0) {
      return <LuCircleDashed className="loading-spinner" />;
    }

    let hotelsCost = 0;

    Object.values(hotelsData).forEach((hotels) => {
      hotels.forEach((hotel) => {
        if (hotel.selected) {
            console.log("o:", hotel.price)
            console.log("p:",parsePrice(hotel.price));
            hotelsCost += parsePrice(hotel.price);
        }
      });
    });

    return hotelsCost;
  };

  // Function to calculate total flight costs
  const calculateFlightCosts = (flightsData) => {
    if (!flightsData || !flightsData.routes || flightsData.routes.length === 0) {
      return <LuCircleDashed className="loading-spinner" />;
    }

    let flightsCost = 0;

    flightsData.routes.forEach((route) => {
      route.flights.forEach((flight) => {
        if (flight.selected) {
          flightsCost += parsePrice(flight.price);
        }
      });
    });

    return flightsCost;
  };

  // Calculate costs
  const hotelCosts = calculateHotelCosts(masterJson.hotelsData);
  const flightCosts = calculateFlightCosts(masterJson.flightsData);

  // Function to safely parse and calculate total costs
  const calculateTotalCost = () => {
    if(hotelCosts && flightCosts){
        console.log(hotelCosts, flightCosts)
        const totalValue = hotelCosts + flightCosts + 1200 + 750; // Add estimated costs

        return formatCurrency(totalValue);
    } else{
        return <LuCircleDashed className="loading-spinner" />;
    }
  };

  return (
    <div className="budget-table-container">
      <div className="budget-table-header">Orçamento da Viagem</div>
      <table className="budget-custom-table">
        <tbody>
          <tr>
            <th>Gastos com Passagens</th>
            <td>{formatCurrency(flightCosts) || <LuCircleDashed className="loading-spinner" />}</td>
          </tr>
          <tr>
            <th>Gastos com Hospedagem</th>
            <td>{formatCurrency(hotelCosts) || <LuCircleDashed className="loading-spinner" />}</td>
          </tr>
          <tr>
            <th>Estimativa para Alimentação</th>
            <td>{formatCurrency(1200)}</td>
          </tr>
          <tr>
            <th>Estimativas com Entretenimento</th>
            <td>{formatCurrency(750)}</td>
          </tr>
          <tr className="budget-total-row">
            <th>Total de Gastos</th>
            <td>{calculateTotalCost()}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default BudgetElement;
