import React, { useEffect, useState } from 'react';
import { FiLogOut } from "react-icons/fi";
import { IoIosMenu } from "react-icons/io";
import { BiWorld } from "react-icons/bi";
import { FaPersonWalkingLuggage } from "react-icons/fa6";
import { FaRegBell, FaRoute } from "react-icons/fa";
import { NavLink, useNavigate, Link } from 'react-router-dom';
import { isUserLoggedIn } from '../utils/auth';

import '../css/sidePanel.css';

const SidePanel = () => {
    const [menuVisible, setMenuVisible] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const serverDirection = process.env.REACT_APP_SERVER_DIRECTION;
    const websiteDirection = process.env.REACT_APP_WEBSITE_DIRECTION;
    const navigate = useNavigate();

    useEffect(() => {
        setIsLoggedIn(isUserLoggedIn());
    }, []);

    return (
        <div className="side-panel-container">
            <div className='side-panel-header'>
                <div className='side-panel-logo'>
                    <img onClick={()=>{navigate('/')}} src={`${websiteDirection}/lilo2.png`} alt="Logo" />
                </div>
                <div className='menu-open-container' onClick={()=>{setMenuVisible(!menuVisible)}}><IoIosMenu/><a>Menu</a></div>
            </div>
            <div className='side-panel-menu' style={{ display: menuVisible ? 'flex' : 'none' }}>
                {/* <NavLink to="/home" className="side-panel-menu-option">
                    {({ isActive }) => (
                        <>
                            <BiWorld className={isActive ? 'option-selected' : ''} />
                            <a>Planejar Viagem</a>
                        </>
                    )}
                </NavLink> */}
                <NavLink to="/itinerary" className="side-panel-menu-option">
                    {({ isActive }) => (
                        <>
                            <FaRoute className={isActive ? 'option-selected' : ''} />
                            <a>Itinerários</a>
                        </>
                    )}
                </NavLink>
                <NavLink to="/myTrips" className="side-panel-menu-option">
                    {({ isActive }) => (
                        <>
                            <FaPersonWalkingLuggage className={isActive ? 'option-selected' : ''} />
                            <a>Minhas Viagens</a>
                        </>
                    )}
                </NavLink>
                {/* <NavLink to="/alerts" className="side-panel-menu-option">
                    {({ isActive }) => (
                        <>
                            <FaRegBell className={isActive ? 'option-selected' : ''} />
                            <a>Alertas</a>
                        </>
                    )}
                </NavLink> */}
                {isLoggedIn && (
                    <NavLink to="/logout" className="side-panel-menu-option side-panel-logout">
                        {({ isActive }) => (
                            <>
                                <FiLogOut className={isActive ? 'option-selected' : ''} />
                                <a>Sair</a>
                            </>
                        )}
                    </NavLink>
                )}
            </div>
        </div>
    );
};

export default SidePanel;