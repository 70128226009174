import React, { useEffect, useState, useRef } from 'react';
import { IoMdCloseCircle } from 'react-icons/io';
import { IoIosSend } from 'react-icons/io';
import { LuCircleDashed } from 'react-icons/lu';
import "../css/chatPanel.css";
import axios from 'axios';

const ChatPanel = ({ chatVisibility, setChatVisibility, masterJson, setMasterJson, page, initialMessage, chatHistory }) => {
    const [chatConversation, setChatConversation] = useState([]);
    const [currentMessage, setCurrentMessage] = useState('');
    const chatContainerRef = useRef(null);
    const [loading, setLoading] = useState(false); // Add loading state
    const [tripID, setTripID] = useState(masterJson.tripID ? masterJson.tripID : null);
    const serverDirection = process.env.REACT_APP_SERVER_DIRECTION;
    const websiteDirection = process.env.REACT_APP_WEBSITE_DIRECTION;

    useEffect(() => {
        if (initialMessage) {
            handleSendMessage(initialMessage);
        }
    }, [initialMessage]);

    useEffect(() => {
        if (chatHistory && chatHistory.length > 0) {
            setChatConversation(chatHistory[0].chatHistory);
            setTripID(masterJson.tripID); // Ensure tripID is set from masterJson
        }
    }, [chatHistory]);

    useEffect(() => {
        setTripID(masterJson.tripID);
        console.log(masterJson); 
    }, [masterJson]);

    useEffect(() => {
        // Scroll to the bottom of the chat container whenever chatConversation updates
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [chatConversation]);
    
    const getUserId = () => {
        const userString = localStorage.getItem('user');
        if (userString) {
            try {
                const user = JSON.parse(userString);
                return user.id;
            } catch (error) {
                console.error('Error parsing user data:', error);
            }
        }
        return null;
    };

    const handleSendMessage = async (message = currentMessage) => {
        if (!message.trim()) return;
    
        const newMessage = { role: 'user', content: message, timestamp: new Date().toISOString() };
        setCurrentMessage('');
        setChatConversation(prevChat => [...prevChat, newMessage]);
        setLoading(true);
    
        try {
            const response = await fetch(`${serverDirection}/replace-itinerary`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    masterJson: masterJson,
                    conversationArray: [...chatConversation, newMessage],
                    userId: getUserId(),
                    tripID:tripID
                })
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            const reader = response.body.getReader();
            const decoder = new TextDecoder();
            let jsonBuffer = '';
    
            while (true) {
                const { value, done } = await reader.read();
                if (done) break;
    
                const decodedChunk = decoder.decode(value, { stream: true });
                jsonBuffer += decodedChunk;
    
                let eventEnd = jsonBuffer.indexOf('\n\n');
                while (eventEnd > -1) {
                    const event = jsonBuffer.slice(0, eventEnd);
                    jsonBuffer = jsonBuffer.slice(eventEnd + 2);
    
                    if (event.startsWith('data:')) {
                        const jsonString = event.slice(5).trim();
                        if (jsonString) {
                            try {
                                const data = JSON.parse(jsonString);
                                // Process the data as before
                                const botReply = { role: 'bot', content: data.message, timestamp: new Date().toISOString() };
                                setChatConversation(prevChat => [...prevChat, botReply]);
    
                                if (data.masterJson) {
                                    setMasterJson(data.masterJson);
                                }
    
                                setLoading(!data.itineraryComplete);
                            } catch (parseError) {
                                console.error('Error parsing JSON:', parseError);
                                console.error('Problematic JSON string:', jsonString);
                            }
                        }
                    }
    
                    eventEnd = jsonBuffer.indexOf('\n\n');
                }
            }
        } catch (error) {
            console.error('Error:', error);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };
    

    return (
        <div className='chat-panel-container' style={{ display: chatVisibility }}>
            <IoMdCloseCircle className='exit-button chat-exit-button' onClick={() => setChatVisibility("none")} />
            <div className='chat-conversation-container' ref={chatContainerRef}>
                    <div className={`chat-message bot-message`}>
                        <img src={`${websiteDirection}/lilo1.png`} alt='Bot Profile' className='profile-image' />
                        {page == 'trip' ? (
                            <div className='message-content'>
                                <p>Bem-vindo! Que tal nos contar mais sobre sua viagem?</p>
                                <ul>
                                    <li>Possíveis destinos</li>
                                    <li>Dúvidas sobre locais específicos</li>
                                    <li>Suas preferências para a viagem (aventura, vida noturna, mundo histórico...)</li>
                                </ul>
                                <p>Exemplo: Gostaria de viajar para o leste europeu, visitando locais históricos da Segunda Guerra Mundial!</p>
                            </div>
                        ) : ''}
                        {page == 'itinerary' ? (
                            <div className='message-content'>
                                <p>Bem-vindo! Que tal nos contar mais sobre sua viagem?</p>
                                <ul>
                                    <li>Possíveis destinos</li>
                                    <li>Dúvidas sobre locais específicos</li>
                                    <li>Suas preferências para a viagem (aventura, vida noturna, mundo histórico...)</li>
                                </ul>
                                <p>Exemplo: Gostaria de viajar para o leste europeu, visitando locais históricos da Segunda Guerra Mundial!</p>
                            </div>
                        ) : ''}
                    </div>
                {chatConversation.map((message, index) => (
                    <div key={index} className={`chat-message ${message.role}-message`}>
                        {message.role === 'bot' && (
                            <img src={`${websiteDirection}/lilo1.png`} alt='Bot Profile' className='profile-image' />
                        )}
                        <div className='message-content'>
                            <p>{message.content}</p>
                        </div>
                    </div>
                ))}
                {loading && (
                    <div className="chat-message bot-message">
                        <LuCircleDashed className="loading-spinner" />
                    </div>
                )}
            </div>
            <div className='chat-conversation-question'>
                <input
                    className='conversation-question-input'
                    placeholder='Continue seu planejamento aqui!'
                    value={currentMessage}
                    onChange={(e) => setCurrentMessage(e.target.value)}
                    onKeyPress={(e) => e.key === 'Enter' && !loading && handleSendMessage()}
                    disabled={loading} // Disable input while loading}
                />
                <IoIosSend className='send-icon' onClick={()=>{handleSendMessage()}} />
            </div>
        </div>
    );
};

export default ChatPanel;
