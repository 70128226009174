import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import SidePanel from '../../components/sidePanel';
import ChatPanel from '../../components/chatPanel';
import ExtraInfoPanel from '../../components/extraInfoPanel';
import '../../css/itineraryPage.css';
import Itinerary from '../../components/itinerary';
import { RiRobot3Fill } from "react-icons/ri";
import axios from 'axios';

const ItineraryPage = () => {

    const location = useLocation();
    const serverDirection = process.env.REACT_APP_SERVER_DIRECTION;
    const websiteDirection = process.env.REACT_APP_WEBSITE_DIRECTION;
    // Extract the tripId query parameter
    const searchParams = new URLSearchParams(location.search);
    const tripId = searchParams.get('tripId');
    const initialMessage = location.state?.initialMessage;

    const [masterJson, setMasterJson] = useState({
        "cities": [
            {
                "name": "",
                "duration": "",
            }
        ],
        "itinerary": {
        }
    });
    const [itineraryHistory, setItineraryHistory] = useState([])

    const [chatHistory, setChatHistory] = useState([]);

    useEffect(() => {
      if(tripId){
        const fetchTrip = async () => {
            const token = localStorage.getItem('token'); // Retrieve the token from localStorage
            try {
                const response = await axios.post(`${serverDirection}/get-my-itinerary`, {
                    tripId: tripId // Pass tripId in the request body
                }, {
                    headers: {
                        'Authorization': `Bearer ${token}` // Include the token in the Authorization header
                    }
                });
                let newMasterJson = response.data.trip[0].itineraryHistory[response.data.trip[0].itineraryHistory.length - 1];
                let history = response.data.trip[0].itineraryHistory.slice(-3);
                newMasterJson.tripID = response.data.trip[0].tripID;
                setMasterJson(newMasterJson);
                setItineraryHistory(history.reverse());
                setChatHistory(response.data.chat);
            } catch (error) {
                console.error('Error fetching trips:', error);
            }
        }
        fetchTrip();
      }
    }, [tripId]);

    useEffect(()=>{
        if(masterJson.itinerary.length > 0 && masterJson.tripID){
            const fetchTrip = async () => {
                const token = localStorage.getItem('token'); // Retrieve the token from localStorage
                try {
                    const response = await axios.post(`${serverDirection}/get-my-itinerary`, {
                        tripId: masterJson.tripID // Pass tripId in the request body
                    }, {
                        headers: {
                            'Authorization': `Bearer ${token}` // Include the token in the Authorization header
                        }
                    });
                    let history = response.data.trip[0].itineraryHistory.slice(-3);
                    setItineraryHistory(history.reverse());
                } catch (error) {
                    console.error('Error fetching trips:', error);
                }
            }
            fetchTrip();        }
    }, [masterJson]);

    const [chatVisible, setChatVisible] = useState();
    const [extraPanelVisible, setExtraPanelVisible] = useState(false);
    const [infoType, setInfoType] = useState('');
    const [selectedActivity, setSelectedActivity] = useState(null);

    function setParameterVisible(extraPanelVisible){
        setExtraPanelVisible(extraPanelVisible);
    }

    function handleActivitySelect(activity) {
        if (activity.tour) {
            setSelectedActivity(activity);
            setInfoType('activity');
            setExtraPanelVisible(true);
        }
    }

    useEffect(() => {
        if(initialMessage){
            setChatVisible('flex')
        }
    }, [initialMessage])
    
    const isItineraryEmpty = !masterJson.itinerary || Object.keys(masterJson.itinerary).length === 0;

    return (
        <div className='page-container'>
            <SidePanel/>
            <div className='page-body-container'>
                {!isItineraryEmpty? (
                    <div className='page-body'>
                        <Itinerary openPanel={handleActivitySelect} masterJson={masterJson} itineraryHistory={itineraryHistory} setMasterJson={setMasterJson}/>
                    </div>
                ) : (
                    <div className='page-body'>
                        <h1 className='waiting-page-h1'>Sua próxima viagem está a um clique de distância!</h1>
                        <div className='waiting-image'><img src="https://img.freepik.com/free-vector/passport-with-flight-tickets-white-background_1308-90930.jpg?t=st=1724936200~exp=1724939800~hmac=0ed4f8ea4bf0a52b01dd29417006c0412986acdfcbd49918f7d0c410f1713f7a&w=740" /></div>
                        <p className='waiting-page-paragraph'>Planeje agora e descubra novas aventuras esperando por você.</p>
                    </div>
                )}
                <ChatPanel initialMessage={initialMessage} chatHistory={chatHistory} page={'itinerary'} chatVisibility = {chatVisible} setChatVisibility={setChatVisible} masterJson = {masterJson} setMasterJson={setMasterJson}/>
                {extraPanelVisible ? <ExtraInfoPanel setParameter={setParameterVisible} containerInfo={selectedActivity} infoType={infoType} /> : ''}
                {chatVisible == "flex" ? '' : <div className="chat-icon-container" onClick={()=>{setChatVisible("flex")}}><RiRobot3Fill /><a>Chat</a></div>}
            </div>
        </div>
    );
};

export default ItineraryPage;